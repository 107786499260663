@tailwind base;

@tailwind components;

@tailwind utilities;

/* purgecss start ignore */
/* Markdown Styles */
/* Global */

.course_curriculum:not(:last-child):before {
  content: "";
  position: absolute;
  top: 0px;
  background-color: #5a67d8;
  bottom: -80px;
  left: 50%;
  transform: translate(-50%);
  width: 3px;
  z-index: 0;
}

.chapter-close {
  position: initial;
}

@media (min-width: 1024px) {
  .chapter-close {
    left: -80px;
    position: absolute;
  }
}

.clap_button>svg {
  display: initial;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.font-source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}

.font-dm-sans {
  font-family: "DM Sans", sans-serif;
}

.font-muli {
  font-family: "Muli", sans-serif;
}

button:focus {
  outline: none;
}

.psuedo-color-bg-gray-100::before {
  box-shadow: 0 8px #f7fafc;
}

.psuedo-color-bg-gray-100::after {
  box-shadow: 0 -8px #f7fafc;
}

.psuedo-color-bg-white::before {
  box-shadow: 0 8px #fff;
}

.psuedo-color-bg-white::after {
  box-shadow: 0 -8px #fff;
}

.psuedo-color-bg-gray-200::before {
  box-shadow: 0 8px#edf2f7;
}

.psuedo-color-bg-gray-200::after {
  box-shadow: 0 -8px#edf2f7;
}

.sidebar-option-rounded::after {
  bottom: -16px;
  border-radius: 0 50% 0;
  border-width: 0 20px 0 0;
  border-color: transparent;
  content: "";
  position: absolute;
  right: 0px;
  width: 16px;
  height: 16px;
}

.sidebar-option-rounded::before {
  top: -16px;
  border-radius: 0 0 50%;
  border-width: 0px 20px 0 0;
  border-color: transparent;
  content: "";
  position: absolute;
  right: 0px;
  width: 16px;
  height: 16px;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border: 1px solid #d5d5d5;
}

/* ::-webkit-scrollbar-button {
}

::-webkit-scrollbar-corner {
} */

*::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* ::-webkit-scrollbar-track-piece {
} */
.text-overflow-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 100;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 100;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ProseMirror {
  min-height: 150px;
  /* max-height: inherit;
  padding: 10px;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  border-radius: 0.375rem;
  border-width: 1px; */
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

/* MUI */
.mui-textfield-outlined {
  --pure-material-safari-helper1: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: var(--pure-material-font,
      "Roboto",
      "Segoe UI",
      BlinkMacSystemFont,
      system-ui,
      -apple-system);
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
}

/* Input, Textarea */
.mui-textfield-outlined>input,
.mui-textfield-outlined>textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px;
  /* Safari */
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: transparent;
  box-shadow: none;
  /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.mui-textfield-outlined>input+span,
.mui-textfield-outlined>textarea+span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  width: 100%;
  max-height: 100%;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.mui-textfield-outlined>input+span::before,
.mui-textfield-outlined>input+span::after,
.mui-textfield-outlined>textarea+span::before,
.mui-textfield-outlined>textarea+span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.mui-textfield-outlined>input+span::before,
.mui-textfield-outlined>textarea+span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.mui-textfield-outlined>input+span::after,
.mui-textfield-outlined>textarea+span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

/* Hover */
.mui-textfield-outlined:hover>input,
.mui-textfield-outlined:hover>textarea {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  border-top-color: transparent;
}

.mui-textfield-outlined:hover>input+span::before,
.mui-textfield-outlined:hover>textarea+span::before,
.mui-textfield-outlined:hover>input+span::after,
.mui-textfield-outlined:hover>textarea+span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

.mui-textfield-outlined:hover>input:not(:focus):placeholder-shown,
.mui-textfield-outlined:hover>textarea:not(:focus):placeholder-shown {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

/* Placeholder-shown */
.mui-textfield-outlined>input:not(:focus):placeholder-shown,
.mui-textfield-outlined>textarea:not(:focus):placeholder-shown {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

.mui-textfield-outlined>input:not(:focus):placeholder-shown+span,
.mui-textfield-outlined>textarea:not(:focus):placeholder-shown+span {
  font-size: inherit;
  line-height: 68px;
}

.mui-textfield-outlined>input:not(:focus):placeholder-shown+span::before,
.mui-textfield-outlined>textarea:not(:focus):placeholder-shown+span::before,
.mui-textfield-outlined>input:not(:focus):placeholder-shown+span::after,
.mui-textfield-outlined>textarea:not(:focus):placeholder-shown+span::after {
  border-top-color: transparent;
}

/* Focus */
.mui-textfield-outlined>input:focus,
.mui-textfield-outlined>textarea:focus {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
    inset -1px 0 var(--pure-material-safari-helper1),
    inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.mui-textfield-outlined>input:focus+span,
.mui-textfield-outlined>textarea:focus+span {
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.mui-textfield-outlined>input:focus+span::before,
.mui-textfield-outlined>input:focus+span::after,
.mui-textfield-outlined>textarea:focus+span::before,
.mui-textfield-outlined>textarea:focus+span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

/* Disabled */
.mui-textfield-outlined>input:disabled,
.mui-textfield-outlined>input:disabled+span,
.mui-textfield-outlined>textarea:disabled,
.mui-textfield-outlined>textarea:disabled+span {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0),
      0.38) !important;
  border-top-color: transparent !important;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.mui-textfield-outlined>input:disabled+span::before,
.mui-textfield-outlined>input:disabled+span::after,
.mui-textfield-outlined>textarea:disabled+span::before,
.mui-textfield-outlined>textarea:disabled+span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0),
      0.38) !important;
}

.mui-textfield-outlined>input:disabled:placeholder-shown,
.mui-textfield-outlined>input:disabled:placeholder-shown+span,
.mui-textfield-outlined>textarea:disabled:placeholder-shown,
.mui-textfield-outlined>textarea:disabled:placeholder-shown+span {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0),
      0.38) !important;
}

.mui-textfield-outlined>input:disabled:placeholder-shown+span::before,
.mui-textfield-outlined>input:disabled:placeholder-shown+span::after,
.mui-textfield-outlined>textarea:disabled:placeholder-shown+span::before,
.mui-textfield-outlined>textarea:disabled:placeholder-shown+span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {

    .mui-textfield-outlined>input,
    .mui-textfield-outlined>input+span,
    .mui-textfield-outlined>textarea,
    .mui-textfield-outlined>textarea+span,
    .mui-textfield-outlined>input+span::before,
    .mui-textfield-outlined>input+span::after,
    .mui-textfield-outlined>textarea+span::before,
    .mui-textfield-outlined>textarea+span::after {
      transition-duration: 0.1s;
    }
  }
}

/* MUI END */

.circular-progress {
  position: relative;
  user-select: none;
}

.circular-progress .circular-progress-movable {
  display: flex;
  cursor: pointer;
}

.circular-progress .radial-filler {
  overflow: visible;
  fill: none;
}

.circular-progress .radial-filler path {
  stroke: lightgray;
  stroke-width: 0.5px;
  transform-origin: center;
}

.circular-progress .radial-filler circle {
  transform-origin: center;
}

.circular-progress .circular-progress-handle {
  background-color: white;
  position: absolute;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0.2);
}

.circular-progress .circular-progress-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* purgecss end ignore */